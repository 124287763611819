<template>
  <div>
    <div class="mb-4">
      <b-row>
        <b-col cols="6">
          <div class="d-flex table-bordered mb-1">
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px"
            >
              Номер
            </div>
            <div class="px-2 py-1 flex-grow-1">{{ order.number }}</div>
          </div>
        </b-col>

        <b-col cols="6">
          <div class="d-flex table-bordered mb-1">
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px"
            >
              Дата
            </div>
            <div class="px-2 py-1 flex-grow-1">
              {{ order.order_date | parseDate }}
            </div>
          </div>
        </b-col>
      </b-row>

      <div class="d-flex table-bordered mb-1">
        <div
          class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
          style="width: 250px"
        >
          Срок исполнения по заказу
        </div>
        <div class="px-2 py-1 flex-grow-1">
          {{ order.deadline_date | parseDate }}
        </div>
      </div>

      <div class="d-flex table-bordered mb-1">
        <div
          class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
          style="width: 250px"
        >
          Статус согласования с заказчиком
        </div>
        <div class="px-2 py-1 flex-grow-1">{{ order.customer_status }}</div>
      </div>

      <div class="d-flex table-bordered mb-1">
        <div
          class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
          style="width: 250px"
        >
          Статус согласования с поставщиком
        </div>
        <div class="px-2 py-1 flex-grow-1">{{ order.provider_status }}</div>
      </div>
    </div>

    <div class="h5">Заказчик-застройщик</div>

    <div class="mb-4">
      <div class="d-flex table-bordered mb-1">
        <div
          class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
          style="width: 250px"
        >
          Филиал АО «Сетевая компания»
        </div>
        <div class="px-2 py-1 flex-grow-1">{{ getOrganization.name }}</div>
      </div>

      <b-row>
        <b-col cols="4">
          <div class="d-flex table-bordered mb-1">
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px"
            >
              Договор подряда
            </div>
            <div class="px-2 py-1 flex-grow-1">
              {{ getWorkAgreement.number }}
            </div>
          </div>
        </b-col>

        <b-col cols="8">
          <div class="d-flex table-bordered mb-1">
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px"
            >
              Дата договора подряда
            </div>
            <div class="px-2 py-1 flex-grow-1">
              {{ getWorkAgreement.date | parseDate }}
            </div>
          </div>
        </b-col>
      </b-row>

      <div class="d-flex table-bordered mb-1">
        <div
          class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
          style="width: 250px"
        >
          Вид работ
        </div>
        <div class="px-2 py-1 flex-grow-1">{{ order.customer.work_type }}</div>
      </div>

      <div class="d-flex table-bordered mb-1">
        <div
          class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
          style="width: 250px"
        >
          Объект
        </div>
        <div class="px-2 py-1 flex-grow-1">{{ getObject && getObject.name }}</div>
      </div>

      <div class="d-flex table-bordered mb-1">
        <div
          class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
          style="width: 250px"
        >
          Подобъект
        </div>
        <div class="px-2 py-1 flex-grow-1">{{ getSubObject.name }}</div>
      </div>

      <b-row>
        <b-col cols="4">
          <div class="d-flex table-bordered mb-1">
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px"
            >
              Начало работ
            </div>
            <div class="px-2 py-1 flex-grow-1">
              {{ order.customer.work_start_date | parseDate }}
            </div>
          </div>
        </b-col>

        <b-col cols="8">
          <div class="d-flex table-bordered mb-1">
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px"
            >
              Окончание работ
            </div>
            <div class="px-2 py-1 flex-grow-1">
              {{ order.customer.work_end_date | parseDate }}
            </div>
          </div>
        </b-col>
      </b-row>
    </div>

    <div class="h5">Поставщик</div>

    <div class="mb-4">
      <div class="d-flex table-bordered mb-1">
        <div
          class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
          style="width: 250px"
        >
          Поставщик
        </div>
        <!-- <div class="px-2 py-1 flex-grow-1">{{ getContrAgent(order.provider.contr_agent_id).name }}</div> -->
        <div class="px-2 py-1 flex-grow-1">
          {{
            order.provider.contr_agent ? order.provider.contr_agent.name : ``
          }}
          <!-- {{ getContrAgent(order.provider.contr_agent_id).name }} -->
        </div>
      </div>

      <b-row>
        <b-col cols="4">
          <div class="d-flex table-bordered mb-1">
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px"
            >
              Договор поставки
            </div>
            <div class="px-2 py-1 flex-grow-1">
              {{ providerContractNumber }}
            </div>
          </div>
        </b-col>

        <b-col cols="8">
          <div class="d-flex table-bordered mb-1">
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px"
            >
              Дата
            </div>
            <div class="px-2 py-1 flex-grow-1">
              {{ getProviderContract.date | parseDate }}
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="4">
          <div class="d-flex table-bordered mb-1">
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px"
            >
              Контактное лицо
            </div>
            <div class="px-2 py-1 flex-grow-1">
              {{ order.provider ? order.provider.full_name : `` }}
            </div>
          </div>
        </b-col>

        <b-col cols="4">
          <div class="d-flex table-bordered mb-1">
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px"
            >
              Адрес электронной почты
            </div>
            <div class="px-2 py-1 flex-grow-1">
              {{ order.provider ? order.provider.email : `` }}
            </div>
          </div>
        </b-col>

        <b-col cols="4">
          <div class="d-flex table-bordered mb-1">
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px"
            >
              Телефон контактные лицо
            </div>
            <div v-if="order.provider" class="px-2 py-1 flex-grow-1">
              {{ order.provider.phone | VMask("+# (###) ###-##-##") }}
            </div>
          </div>
        </b-col>
      </b-row>

      <div
        v-if="order.provider.agreed_comment"
        class="d-flex table-bordered mb-1"
      >
        <div
          class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
          style="width: 250px"
        >
          Комментарий поставщика по согласованным позициям
        </div>
        <div class="px-2 py-1 flex-grow-1">
          {{ order.provider.agreed_comment }}
        </div>
      </div>

      <div
        v-if="order.provider.rejected_comment"
        class="d-flex table-bordered mb-1"
      >
        <div
          class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
          style="width: 250px"
        >
          Комментарий поставщика по отказанным позициям
        </div>
        <div class="px-2 py-1 flex-grow-1">
          {{ order.provider.rejected_comment }}
        </div>
      </div>
    </div>

    <div class="h5">Подрядчик</div>

    <div class="mb-4">
      <div class="d-flex table-bordered mb-1">
        <div
          class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
          style="width: 250px"
        >
          Подрядчик
        </div>
        <div class="px-2 py-1 flex-grow-1">
          {{
            order.contractor.contr_agent
              ? order.contractor.contr_agent.name
              : ``
          }}
          <!-- {{ getContrAgent(order.contractor.contr_agent_id).name }} -->
        </div>
      </div>

      <b-row>
        <b-col cols="4">
          <div class="d-flex table-bordered mb-1">
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px"
            >
              Контактное лицо
            </div>
            <div class="px-2 py-1 flex-grow-1">
              {{ order.contractor ? order.contractor.full_name : `` }}
            </div>
          </div>
        </b-col>

        <b-col cols="4">
          <div class="d-flex table-bordered mb-1">
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px"
            >
              Адрес электронной почты
            </div>
            <div class="px-2 py-1 flex-grow-1">
              {{ order.contractor ? order.contractor.email : `` }}
            </div>
          </div>
        </b-col>

        <b-col cols="4">
          <div class="d-flex table-bordered mb-1">
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px"
            >
              Телефон контактные лицо
            </div>
            <div class="px-2 py-1 flex-grow-1">
              {{ order.contractor.phone | VMask("# (###) ###-##-##") }}
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="8">
          <div class="d-flex table-bordered mb-1">
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px"
            >
              Ответственный на объекте
            </div>
            <div class="px-2 py-1 flex-grow-1">
              {{ objectResponsibleFullName }}
            </div>
          </div>
        </b-col>

        <b-col cols="4">
          <div class="d-flex table-bordered mb-1">
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px"
            >
              Телефон ответственного
            </div>
            <div class="px-2 py-1 flex-grow-1">
              {{
                objectResponsiblePhone | VMask("# (###) ###-##-##")
              }}
            </div>
          </div>
        </b-col>
      </b-row>

      <div class="d-flex table-bordered mb-1">
        <div
          class="px-2 py-1 font-weight-bold border-right bg-light text-muted flex-shrink-0"
          style="width: 250px"
        >
          Комментарий подрядчика
        </div>
        <div class="px-2 py-1 flex-grow-1">{{ order.contractor.contract }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderInformation",
  computed: {
    order() {
      return this.$store.state.orders.currentOrder;
    },
    getOrganization() {
      return this.$store.getters.getOrganization(
        this.order.customer?.organization_id
      );
    },
    getSubObject() {
      return this.$store.getters.getSubObject(
        this.order.customer?.sub_object_id
      );
    },
    getWorkAgreement() {
      return this.$store.getters.getWorkAgreement(
        this.order.customer?.work_agreement_id
      );
    },
    getObject() {
      return this.order.customer?.object;
    },
    getProviderContract() {
      return this.$store.getters.getProviderContract(
        this.order.provider?.contract_id
      );
    },
    providerContractNumber() {
      try {
        return this.order.provider.contract.number
      } catch( e ) {
        return null
      }
    },
    objectResponsibleFullName() {
      try {
        return this.order.contractor.contractor_responsible_full_name
      } catch( e ) {
        return null
      }
    },
    objectResponsiblePhone() {
      try {
        return this.order.contractor.contractor_responsible_phone
      } catch( e ) {
        return null
      }
    }
  },
  methods: {
    getContrAgent(id) {
      return this.$store.getters.getContrAgent(id);
    },
  },
};
</script>

<style
    lang="sass"
    scoped
>
</style>
